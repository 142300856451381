import React from 'react';
import { Provider, useSelector } from 'react-redux';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import store from 'store';
import App from 'app/App';
import { getAppInsights } from './TelemetryService';
import TelemetryProvider from './telemetry-provider';
import config from 'config';
import RouteChangeTracker from 'routes/route-tracking';
import Tracker from 'app/tracking/tracking-service';
import { useState } from 'react';
import {
  AppMetaProvider,
  changeThemeColor
} from 'pub-components/Sidebar/helper';
import { useEffect } from 'react';
import { getCookie } from 'services/utils/generic.methods';
import storage from 'services/utils/storage';
import { toast } from 'react-toastify';
import { toastMsg } from 'services/utils/variables';
import { getUserPreferenceSettings } from 'store/slices/userPreferenceSettings/userPreferenceSlice';
import { getCompanyDynamicConfig } from 'store/slices/company/companySlice';
import { setCompanyConfig } from 'store/slices/general/generalSlice';

Tracker.Initialize();
// Note: for testing only.
// Tracker.Event(1, {
//   category: '1PAGE_LOAD',
//   action: '1REFRESH',
//   label: '1The Page Loaded'
// });
const Root = () => {
  let appInsights = null;
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [_dynamicMeta, setDynamicMeta] = useState(null);
  const user = JSON.parse(storage.getItem('Profile'));
  const [prefLoaded, setPrefLoaded] = useState(false);
  const [companyLoaded, setCompanyLoaded] = useState(false);
  const setHostKey = () => {
    let keys = window.location.pathname.split('/');
    if (keys.includes('login'))
      storage.setItem('AppMetaInfo', { host: keys[2] || '' });
  };
  if (!config.instrumentationConnectionString) {
    console.warn('Instrument key is not defined in the config file.');
  }
  let intervalId = 0;
  useEffect(() => {
    if (!_dynamicMeta) {
      setLoading(true);
      // Need to set HostKey manually because we get wrong hostkey when fetching MetaAPI
      setHostKey();
      fetchAppMeta();
      if (user?.UserID) {
        if (!user?.UserRoles.includes('MessagingLeadsAccessOnly')) {
          getCompanyConfig();
        } else {
          setCompanyLoaded(true);
        }
        store
          .dispatch(getUserPreferenceSettings({ params: { id: user.UserID } }))
          .then(data => setPrefLoaded(true));
      } else {
        setPrefLoaded(true);
        setCompanyLoaded(true);
      }
    }
  }, [_dynamicMeta]);

  useEffect(() => {
    if (progress === 100 && _dynamicMeta && prefLoaded && companyLoaded) {
      setTimeout(() => {
        clearInterval(intervalId);
        setLoading(false);
      }, 500);
    } else {
      if (loading) {
        intervalId = setInterval(() => {
          setProgress(prevProgress => {
            const randomInRange = Math.random() * (60 - 10 + 1) + 10; // Get random number in from 10 to 60
            const randomIncrement = Math.floor(randomInRange);
            const newProgress = prevProgress + randomIncrement;
            return newProgress > 100 ? 100 : newProgress;
          });
        }, 1000);
      }
    }
  }, [progress, loading, _dynamicMeta, prefLoaded, companyLoaded]);

  const fetchAppMeta = async function () {
    try {
      let meta = await AppMetaProvider().GetDynamicMeta();
      setDynamicMeta(meta);
    } catch (ex) {
      toast.error(toastMsg.errors.failure);
      setLoading(false);
    }
  };

  const getCompanyConfig = async () => {
    try {
      let { data } = await getCompanyDynamicConfig();
      if (data && data?.success) {
        let config = { ...data?.data };
        store.dispatch(setCompanyConfig(config));
      }
      setCompanyLoaded(true);
    } catch (error) {
      setCompanyLoaded(true);
      console.error(error);
    }
  };

  //The <Provider /> makes the Redux store available to any nested components
  // that have been wrapped in the connect() function
  return loading ? (
    <div
      className="d-flex flex-column align-items-center justify-content-center"
      style={{ height: '100vh' }}
    >
      <h5>{progress}% </h5>
      <br />
      <div className="progress" style={{ height: '2px', width: '500px' }}>
        <div
          className="progress-bar"
          role="progressbar"
          style={{ width: progress + '%', backgroundColor: '#000' }}
          aria-valuenow={progress}
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
    </div>
  ) : (
    <TelemetryProvider
      instrumentationKey={config.instrumentationConnectionString}
      after={() => {
        appInsights = getAppInsights();
      }}
    >
      <Provider store={store}>
        <Router>
          <RouteChangeTracker />
          <Route path="/" component={App} />
        </Router>
      </Provider>
    </TelemetryProvider>
  );
};

export default Root;
