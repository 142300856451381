import { ToastTemplate } from './generic.methods';

export const errors = {
  empty: 'Should not be empty',
  samePassword: 'Password must match',
  changepassword: 'Password can not be changed',
  login: 'You have entered invalid user information, please try again',
  fieldError: name => `The ${name.toLowerCase()} field should not be empty`,
  fieldCorrection: name => `Please use the correct ${name}`,
  inValidAuthToken: `Your session has expired, please login again.`,
  saveError: name => `Something went wrong while saving the changes in ${name}`,
  createError: 'Something went wrong while saving',
  notValid: {
    email: 'Should be a valid email address',
    phone: 'should be a valid phone number',
    phoneNumber: 'Should be a valid phone number e.g., 123-456-7890',
    url: 'Should be a valid URL',
    zip: 'Should be a valid zip code',
    zipLength: 'Max 10 Characters Allowed',
    phoneLength: 'Max 17 Characters Allowed',
    consultationFee: 'Max 128 Characters Allowed',
    characterLimit: limit => `Max ${limit} Characters Allowed`,
    specialCharacter: 'This special character is not allowed',
    text: 'Must only contain alphabets',
    name: 'Should be a valid name',
    address: 'Should be a valid address',
    multiemail:
      'Enter valid email address with , without space for other email',
    fax: 'Shoud be a valid fax number'
  },
  networkError: 'Network error, please check your connectivity',
  failure: 'Something went wrong',
  defaultUserMD:
    'Default user warning !! Note: Default users cannot handle chats. Please contact your account representative if you need access to the messaging dashboard.',
  range: 'Number should be 0 to 100'
};
export const toastMsg = {
  errors: {
    inValidAuthToken: ToastTemplate.error(errors.inValidAuthToken),
    networkError: ToastTemplate.error(errors.networkError),
    failure: ToastTemplate.error(errors.failure),
    login: ToastTemplate.error(errors.login),
    saveError: name => ToastTemplate.error(errors.saveError(name)),
    changepassword: ToastTemplate.error(errors.changepassword),
    createError: ToastTemplate.error(errors.createError)
  }
};
export const success = {
  forgotPassword: ToastTemplate.success(
    'Password has been successfully reset, please check your email'
  ),
  saveSuccess: (name, text = 'saved') =>
    ToastTemplate.success(`${name} has been ${text} successfully`),
  sendSuccess: name =>
    ToastTemplate.success(`${name} has been sent successfully`),
  callConnectSuccess: ToastTemplate.success(
    'Your request has been received the call will be connected soon'
  ),
  changePassword: ToastTemplate.success(
    'Your password has been changed successfully'
  ),
  chatEnd: ToastTemplate.success(`Chat has been ended successfully`),
  chatRejected: ToastTemplate.success(`Chat has been rejected successfully`),
  statusChange: ToastTemplate.success(`Status has been changed successfully`),
  updateLead: chatId => ToastTemplate.success(`${chatId} updated`),
  runSuccess: name =>
    ToastTemplate.success(`${name} has been run successfully`),
  deleteSuccess: name => ToastTemplate.success(`${name} has been deleted`),
  custom: text => ToastTemplate.success(text),
  duplicateSuccess: name =>
    ToastTemplate.success(`${name} has been duplicated`),
  redirectingToPortal: ToastTemplate.success('Redirecting to the Portal...')
};

export const duration = {
  timeout5s: 5000,
  copiedBadge: { duration: 2600, delay: 300 }
};

export const theme = {
  blue: '#2d2d95',
  primary: '#2d2d95',
  darkgray: '#212b36',
  secondary: '#212b36',
  red: '#bf0711',
  danger: '#bf0711'
};

export const breakpoints = {
  tabletAndSmallerDevices: 767
};

export const domainExceptionList = [
  'http://testportal.vialivechat.com/',
  'http://192.168.0.132/'
];

export const unAuthorizedErrors = [
  'Invalid JWT token',
  "Authorization header sent, but was not in the format 'Bearer {token}'"
];
