import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAlertState,
  hideAlert,
  setSwalLoading,
  showAlert
} from 'store/slices/general/generalSlice';

const useSweetAlert = () => {
  const dispatch = useDispatch();
  const alertState = useSelector(getAlertState);

  const showSweetAlert = useCallback(
    params => {
      dispatch(
        showAlert({
          ...params,
          onClose: params?.onClose || hideSweetAlert
        })
      );
    },
    [dispatch]
  );

  const hideSweetAlert = useCallback(() => {
    dispatch(hideAlert());
  }, [dispatch]);

  const setAlertLoading = useCallback(
    value => {
      dispatch(setSwalLoading(value));
    },
    [dispatch]
  );

  return {
    showSweetAlert,
    hideSweetAlert,
    setAlertLoading,
    alertState
  };
};

export default useSweetAlert;
