export const fr_provisioningDetails = {
  heading1: "Les détails d'approvisionnement",
  heading2: 'Établissement des prix',
  heading3: 'Operations commerciales',
  heading4: 'Pièces jointes',
  heading5: 'Détails de base',
  heading6: 'Paquets',
  features: {
    info: `Il s'agit des frais {{description}} à {{amount}} facturés {{paymentType}}`,
    noFeatureSelectedWarningHeading: `Aucune fonctionnalité sélectionnée`,
    noFeatureSelectedWarningMessage: `Vous n'avez sélectionné aucune fonctionnalité. Voulez-vous continuer ?`,
    yes: 'Oui',
    no: 'Non'
  },
  companyName: {
    label: 'Nom de la compagnie',
    info: 'Le nom de la société.'
  },
  fileName: {
    label: 'Nom de fichier',
    info: ''
  },
  downloadFile: {
    label: 'Download',
    info: ''
  },
  companyKeyRequest: {
    label: "Demande clé de l'entreprise",
    info: "Utilisez ce champ pour la clé d'entreprise proposée."
  },
  status: {
    label: 'Statut',
    info: "Qu'est-ce que vous entrez êtes dans la demande Onboarding."
  },
  partnerRepresentativeName: {
    label: 'Nom représentant partenaire',
    info: 'Insérer le nom (s) qui recevra la communication e-mail et le point de contact principal pour le compte.'
  },
  representativeEmail: {
    label: 'email représentant',
    info: "Insérez l'e-mail (s) qui recevront la communication par courriel de l'Onboarding et principal point de contact pour le compte."
  },
  leadEmails: {
    label: 'Emails plomb',
    info: 'Les email receiepents qui recevront la tête HTML.'
  },
  website: {
    label: 'Site Internet',
    info: "Le site de l'entreprise."
  },
  googleAnalyticsCode: {
    label: 'Le code de Google',
    info: 'Code de compte Google Analytics.'
  },
  crm: {
    label: 'CRM',
    info: 'Sélectionnez le CRM le client souhaite intégrer.'
  },
  crmEmail: {
    label: 'CRM Email',
    info: 'Sélectionnez le CRM que le client souhaite intégrer.'
  },
  setupInstructions: {
    label: 'Instructions de configuration.',
    info: 'Utilisez ce champ pour toute information additonal qui peuvent être pertinents.'
  },
  attachements: {
    label: 'Pièces jointes',
    info: 'Fournir nécessaires (logo Pièces jointes, agent, documents, etc.).'
  },
  companyKey: {
    label: "Clé de l'entreprise",
    info: "Insérez une clé de l'entreprise pour l'entreprise (corps d'URL du site)."
  },
  businessCategory: {
    label: 'Catégorie Business',
    info: "Sélectionnez l'industrie qui au mieux la l'entreprise."
  },
  subCategories: {
    label: 'Sous-catégories',
    info: "Sélectionnez une sous-catégorie pour l'entreprise si une option est disponible."
  },
  pricing: {
    label: 'Établissement des prix',
    info: "Fournir de l'information pour le prix du service de facturation."
  },
  setupFee: {
    label: "Frais d'installation?",
    info: "Activer les frais d'installation le cas échéant."
  },
  smsFee: {
    label: 'Frais de SMS?',
    info: 'Activer si la fonction SMS est nécessaire.'
  },
  exitPopupFee: {
    label: 'Frais de sortie popup?',
    info: 'Activer si la sortie fonction pop-up est nécessaire.'
  },
  facebookFee: {
    label: 'Frais Facebook?',
    info: 'Activer si la fonction Facebook est nécessaire.'
  },
  spanishFee: {
    label: 'Frais espagnol?',
    info: "Activer si l'entreprise exige que les agents espagnols."
  },
  autoDealerCRMEmail: {
    label: 'Concessionnaire automobile email CRM',
    info: 'Insérer courriel (s) pour le courrier électronique CRM concessionnaire automobile.'
  },
  sendDealerCRMEmail: {
    label: 'Envoyer revendeur email CRM',
    info: "Activer si l'accord automatique fonction de courrier électronique CRM est nécessaire."
  },
  freeConsultation: {
    label: 'Consultation gratuite',
    info: 'Activer si une consultation gratuite est disponible.'
  },
  consultationFee: {
    label: 'Frais de consultation',
    info: "S'il vous plaît fournir les frais de consultation ou des informations utiles qui peuvent aider en ce qui concerne les prix."
  },
  openAccount: {
    label: 'Compte ouvert',
    info: 'Compte ouvert'
  },
  // ==============
  areaOfCoverage: {
    label: 'Zone de couverture',
    info: "La zone de couverture pour l'entreprise."
  },
  linkToPrivacyPolicy: {
    label: 'Lien vers la politique de confidentialité',
    info: "Un lien vers la politique de confidentialité de l'entreprise."
  },
  scriptTitlePosition: {
    label: 'Script position de titre',
    info: 'Titre de la position (dans le script) de la personne qui communiquera avec le visiteur après le chat. Exemple: Représentant, Consultant, superviseur, etc.'
  },
  hipaa: {
    label: 'HIPPA',
    info: 'Cochez cette case si la société exige HIPPA.'
  },
  languages: {
    label: 'Support linguistique',
    info: "Spécifiez les langues demandées pour les scripts de cette entreprise et le soutien de l'agent."
  },
  callConnect: {
    label: 'Appeler connecter',
    info: "Si elle est définie, les agents se connecteront le visiteur et affaires avec un appel téléphonique pendant le chat en direct dans un ensemble spécifique d'heures."
  },
  callConnectPhone: {
    label: 'Appeler le numéro de connexion',
    info: "Insérez le numéro de téléphone d'affaires de l'entreprise. L'entreprise sera disponible pour répondre à ces appels téléphoniques au cours d'une série spécifique d'heures."
  },
  callConnectTranscript: {
    label: 'transcription appel à la connexion',
    info: 'Si elle est définie, le bouton « Call Connect » envoie automatiquement la transcription des visiteurs lorsque la connexion initiale succède.'
  },
  callConnectHours: {
    label: 'Appelez heures de connexion',
    info: "Les heures de connexion d'appel pour l'entreprise."
  },
  insuranceCoverageResponse: {
    label: "Réponse de la couverture d'assurance",
    info: "Si aucune information spécifique applicable, qui devrait être donnée sur la couverture d'assurance."
  },
  specialInstructions: {
    label: 'Instructions spéciales',
    info: "Placez toutes les instructions spéciales qui aideront l'équipe à l'introduction à configurer le compte."
  },
  officeHoursTimezone: {
    label: 'Fuseau horaire',
    info: "Le fuseau horaire de l'entreprise."
  },
  createdOn: {
    label: 'Créé sur',
    info: 'Lorsque la société a été créée au sein du système.'
  },
  modifiedOn: {
    label: 'Modifié le',
    info: 'La dernière date a été modifié la société.'
  },
  businessHours: {
    label: 'Heures de travail',
    info: "Les heures d'ouverture pour l'entreprise."
  },
  officeHours: {
    label: 'Heures de travail',
    info: 'Les heures d`ouverture du bureau.'
  },
  quickConnectHours: {
    label: `Heures de connexion d'appel`,
    info: 'Les heures d`ouverture de Call Connect.'
  },
  billingEmailAddress: {
    label: 'Adresse e-mail du contact de facturation',
    info: `Les destinataires de l'e-mail qui recevront les informations de facturation.`
  },
  address: { label: 'Adresse' },
  fax: { label: 'Fax' },
  inboundOutBound: { label: 'Programmation Entrante ou Sortante' },
  callTransfers: { label: "Transferts d'Appels" },
  currentMonthlyCallVol: { label: "Volume d'Appels Mensuel Actuel" },
  callCenterHours: { label: "Heures de Couverture du Centre d'Appels" },
  returnMissedCalls: { label: 'Renvoyer les Appels Abandonnés/Manqués' },
  minuteUsage: { label: 'Utilisation Prévue des Minutes' },
  expectedLiveDate: { label: 'Date de Mise en Service Prévue' },
  name: { label: 'Nom' },
  email: { label: 'E-mail', info: 'Seulement supporté par Outlook et Gmail.' },
  phoneNumber: { label: 'Numéro de Téléphone' },
  personName: { label: `nom de contacte d'une personne` },
  personEmail: { label: 'E-mail de la Personne de Contact' },
  callTransferPhone: {
    label: "Numéro de Téléphone pour les Transferts d'Appels"
  },
  contactDirectly: { label: 'Pouvons-nous Contacter le Client Directement?' },
  addPhoneNumber: { label: 'Ajouter un Numéro de Téléphone' },
  yes: { label: 'Oui' },
  no: { label: 'Non' },
  appointmentScheduling: { label: 'Planification des Rendez-vous' },
  infoMsg1: {
    label:
      'Nous proposons deux options de planification des rendez-vous : Service de luxe et Libre-service. Veuillez choisir celle qui correspond le mieux à vos besoins.'
  },
  infoMsg2: {
    label:
      "Pour attribuer un représentant, vous devez d'abord Ajouter un Représentant au système."
  },
  infoMsg3: {
    label: 'Vous êtes prêt à Ajouter un Type de Rendez-vous maintenant.'
  },
  title: { label: 'Titre' },
  whiteGloveScheduling: { label: 'Planification de Luxe' },
  whiteGloveDesc: {
    label:
      "Avec la planification de luxe, vous avez la flexibilité d'utiliser votre propre lien de calendrier, tel que Calendly, ce qui permet à nos agents de réserver facilement des rendez-vous."
  },
  selfServeScheduling: { label: 'Planification en Libre-Service' },
  selfServeDesc: {
    label:
      "La planification en libre-service vous permet d'utiliser notre calendrier pour générer des liens de planification, qui peuvent ensuite être utilisés pour réserver des rendez-vous en votre nom."
  },
  representativeDetails: { label: 'Détails du Représentant' },
  addRepresentative: { label: 'Ajouter un Représentant' },
  appointmentTypeDetails: { label: 'Détails du Type de Rendez-vous' },
  addAppointmentType: { label: 'Ajouter un Type de Rendez-vous' },
  infoMsg4: {
    label:
      "Une invitation par e-mail sera envoyée à l'adresse e-mail fournie. Veuillez faire un suivi avec la personne après avoir envoyé l'invitation."
  },
  provider: { label: 'Fournisseur' },
  shiftHours: { label: 'Heures de travail' },
  addAnotherShift: { label: 'Ajouter un autre quart de travail' },
  mediumOfAppointment: { label: 'Moyen préféré de rendez-vous' },
  inPerson: { label: 'En personne' },
  phone: { label: 'Téléphone' },
  video: { label: 'Vidéo' },
  groupMembers: { label: 'Membres du groupe' },
  videoLink: { label: 'Lien vidéo' },
  officialBusinessName: { label: 'Nom Commercial Officiel' },
  officialBusinessAddress: { label: 'Adresse Commerciale Officielle' },
  city: { label: 'Ville' },
  state: { label: 'État' },
  zip: { label: 'Code Postal' },
  country: { label: 'Pays' },
  aliasIsProfession: { label: 'Alias est Profession' },
  article: {
    label: 'Article',
    info: "Permettre à l'utilisateur d'ajouter des articles dans la description de l'e-mail si nécessaire."
  },
  userAlias: { label: "Alias de l'Utilisateur" },
  serviceProvider: { label: 'Fournisseur de Services' },
  serviceTypes: { label: 'Gérer les types de services' },
  addPage: { label: 'Ajouter une page' },
  addThirdPartyPage: { label: 'Ajouter une page tierce' },
  companyRepresentatives: { label: `Gérer les représentants de l'entreprise` },
  uniqueLink: { label: 'Lien Unique' },
  salesRepresentatives: { label: 'Représentants Commerciaux' }
};
export default fr_provisioningDetails;
