import React, { useState } from 'react';
import {
  CancelRounded,
  CheckCircleRounded,
  ErrorRounded,
  WarningRounded
} from '@material-ui/icons';
import { Button, Modal, Spinner } from 'react-bootstrap';
import './style.scss';

const SweetAlert = ({
  show,
  handleClose,
  handleSubmit,
  type = 'success',
  heading = '',
  content,
  loading = false,
  closeBtn = true,
  saveBtn = false,
  closeBtnText = 'Cancel',
  saveBtnText = 'Continue'
}) => {
  const iconByType = {
    success: <CheckCircleRounded />,
    error: <CancelRounded />,
    warning: <ErrorRounded />
  };
  return (
    <Modal
      show={show}
      onHide={handleClose}
      size={'md'}
      keyboard={false}
      id="viewModal"
      dialogClassName="viewModal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="swal-container">
          <div className="swal-icon">{iconByType[type]}</div>
          <div className="py-4">
            <h3 className="font-weight-bold">{heading}</h3>
            <p>{content}</p>
          </div>
          <div className="d-flex" style={{ gap: '10px' }}>
            {closeBtn && (
              <Button
                variant={saveBtn ? 'secondary' : 'primary'}
                className="btn-md px-5"
                onClick={handleClose}
                disabled={loading} // Disable button while loading
              >
                {closeBtnText}
              </Button>
            )}
            {saveBtn && (
              <Button
                variant="primary"
                className="btn-md px-5"
                onClick={handleSubmit}
                disabled={loading} // Disable button while loading
              >
                {loading && <i className="uil uil-spinner spinner mr-1"></i>}
                {saveBtnText}
              </Button>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SweetAlert;
