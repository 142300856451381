import React from 'react';

const StatusIndicator = ({ value, customData = false }) => {
  const getButtonValue = statusId => {
    if (statusId === 1) {
      return <span>Adding Info</span>;
    } else if (statusId === 2) {
      return <span>Waiting For Setup</span>;
    } else if (statusId === 3) {
      return <span>Need More Information</span>;
    } else if (statusId === 4) {
      return <span>Pending Addition To Website</span>;
    } else if (statusId === 5) {
      return <span>Completed</span>;
    }
  };
  const getClass = statusCode => {
    if (statusCode === 1) {
      return 'text-primary';
    } else if (statusCode === 2) {
      return 'text-warning';
    } else if (statusCode === 3) {
      return 'text-danger';
    } else if (statusCode === 4) {
      return 'text-darkgray';
    } else if (statusCode === 5) {
      return 'text-success';
    }
  };

  return (
    <span className={customData ? value?.color : getClass(value)}>
      {customData ? value?.label : getButtonValue(value)}
    </span>
  );
};

export default StatusIndicator;
