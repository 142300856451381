export const en_contactInformation = {
  heading1: 'Contact Information',
  heading2: 'Primary details',
  primaryContactName: {
    label: 'Name',
    info: 'The primary contact name who will be the main point of contact for the account.'
  },
  officialBusinessName: { label: 'Official Business Name' },
  primaryContactEmail: {
    label: 'Email',
    info: 'The primary email contact will be the main point of contact for the account.'
  },
  primaryContactPhone: {
    label: 'Phone',
    info: 'The primary phone contact will be the main point of contact for the account.'
  },
  primaryContactAltPhone: {
    label: 'Alternate phone',
    info: 'An alternate phone number who can be contacted for the account'
  },
  address: {
    label: 'Address',
    info: 'The main address of the business.'
  },
  city: {
    label: 'City',
    info: 'The city where the business is located.'
  },
  state: {
    label: 'State',
    info: 'The state where the business is located'
  },
  zip: {
    label: 'Zip Code',
    info: 'The zip code where the business is located.'
  },
  serviceArea: {
    label: 'Service area',
    info: 'The service and coverage area of the business. (ex: Worldwide, Nationwide, State, cities, etc.)'
  },
  country: {
    label: 'Country',
    info: 'The country where the business is located.'
  },
  invoiceEmail: {
    label: 'Billing Contact email',
    info: 'The email receiepents who will receive the billing information.'
  }
};
export default en_contactInformation;
export { default as fr_contactInformation } from '../fr-FR-Dictionary/fr-contactInformation';
export { default as sp_contactInformation } from '../sp-SP-Dictionary/sp-contactInformation';
