export const sp_provisioningDetails = {
  heading1: 'Aprovisionamiento detalles',
  heading2: 'La fijación de precios',
  heading3: 'Operaciones de negocios',
  heading4: 'Archivos adjuntos',
  heading5: 'Detalles básicos',
  heading6: 'Paquetes',
  features: {
    info: `Esta es la tarifa de {{description}} a {{amount}} cobrados {{paymentType}}`,
    noFeatureSelectedWarningHeading: 'Ninguna característica seleccionada',
    noFeatureSelectedWarningMessage: `No ha seleccionado ninguna función. ¿Desea continuar?`,
    yes: 'Sí',
    no: 'No'
  },
  officialBusinessName: { label: 'Nombre Comercial Oficial' },
  officialBusinessAddress: { label: 'Dirección Comercial Oficial' },
  title: { label: 'Título' },
  companyName: {
    label: 'Nombre de empresa',
    info: 'El nombre de la compañía.'
  },
  fileName: {
    label: 'Nombre del archivo',
    info: ''
  },
  downloadFile: {
    label: 'Descargar',
    info: ''
  },
  companyKeyRequest: {
    label: 'Solicitud de clave de empresa',
    info: 'Utilice este campo para la clave de la compañía sugerido.'
  },
  status: {
    label: 'Estado',
    info: 'Qué paso que está dentro de la solicitud de incorporación.'
  },
  partnerRepresentativeName: {
    label: 'Compañero de correo nombre representativo',
    info: 'Introduzca el nombre (s) que recibirá comunicación por correo electrónico y principal punto de contacto para la cuenta.'
  },
  representativeEmail: {
    label: 'correo electrónico representativa',
    info: 'Introduzca el correo electrónico (s) que van a recibir comunicaciones por correo electrónico de la incorporación y el principal punto de contacto para la cuenta.'
  },
  leadEmails: {
    label: 'Correos electrónicos de plomo',
    info: 'Los receiepents correo electrónico que recibirán la ventaja HTML'
  },
  website: {
    label: 'Sitio web',
    info: 'SLa página web de la empresa.'
  },
  googleAnalyticsCode: {
    label: 'Código de Google Analytics',
    info: 'código de cuenta de Google Analytics.'
  },
  crm: {
    label: 'CRM',
    info: 'Seleccione la CRM el cliente quiere integrar.'
  },
  crmEmail: {
    label: 'Correo electrónico de CRM',
    info: 'Seleccione el CRM que el cliente desea integrar.'
  },
  setupInstructions: {
    label: 'Instrucciones de configuración',
    info: 'Utilice este campo para cualquier adicionales de información que pueda ser relevante.'
  },
  attachements: {
    label: 'Archivos adjuntos',
    info: 'Proporcionar los archivos adjuntos necesarios (logotipo, agente, documentos, etc.)'
  },
  companyKey: {
    label: 'Clave de la empresa',
    info: 'Insertar una llave empresa para el negocio (el cuerpo de la URL del sitio web).'
  },
  businessCategory: {
    label: 'Categoría de negocios',
    info: 'Seleccione el que la industria mejor categoriza el negocio.'
  },
  subCategories: {
    label: 'Subcategorías',
    info: 'Seleccionar una categoría secundaria para la empresa si la opción está disponible.'
  },
  pricing: {
    label: 'La fijación de precios',
    info: 'Proporcionar la información de precios para el departamento de facturación.'
  },
  setupFee: {
    label: 'Cuota de instalación?',
    info: 'Habilitar la cuota de instalación si es aplicable.'
  },
  smsFee: {
    label: 'Cuota de SMS?',
    info: 'Activar si se requiere la función SMS.'
  },
  exitPopupFee: {
    label: 'Cuota de popup de la salida?',
    info: 'Activar si se requiere la función de Salida Pop-up.'
  },
  facebookFee: {
    label: 'Cuota de Facebook?',
    info: 'Activar si se requiere la función de Facebook.'
  },
  spanishFee: {
    label: 'Cuota de español?',
    info: 'Activar si el negocio requiere que los agentes españoles.'
  },
  autoDealerCRMEmail: {
    label: 'Distribuidor automático de correo electrónico CRM',
    info: 'Insertar correo electrónico (s) para el distribuidor automático de correo electrónico CRM.'
  },
  sendDealerCRMEmail: {
    label: 'Enviar distribuidor de correo electrónico CRM',
    info: 'Activar si se requiere la función de correo electrónico CRM acuerdo Auto.'
  },
  freeConsultation: {
    label: 'Consulta gratis',
    info: 'Habilitar si una consulta gratuita está disponible.'
  },
  consultationFee: {
    label: 'Precio de la consulta',
    info: 'Por favor proporcione la consulta de pago o información útil que puede ayudar en materia de precios.'
  },
  openAccount: {
    label: 'Cuenta abierta',
    info: 'Cuenta abierta'
  },
  // ==============
  areaOfCoverage: {
    label: 'El área de cobertura',
    info: 'El área de cobertura para el negocio.'
  },
  linkToPrivacyPolicy: {
    label: 'Enlace a la política de privacidad',
    info: 'Un enlace a la política de privacidad de la compañía.'
  },
  scriptTitlePosition: {
    label: 'posición título del guión',
    info: 'Título de la posición (en el guión) de la persona que va a ponerse en contacto con el visitante después de la charla. Ejemplo: Representante, consultor, supervisor, etc.'
  },
  hipaa: {
    label: 'HIPPA',
    info: 'Marque esta casilla si la empresa requiere HIPPA.'
  },
  languages: {
    label: 'Ayuda de idioma',
    info: 'Especificar los idiomas solicitados para las secuencias de comandos de esta empresa y compatibilidad con el agente.'
  },
  callConnect: {
    label: 'Conexión de llamada',
    info: 'Si se activa, los agentes se conectarán al visitante y de negocios con una llamada telefónica durante el chat en vivo dentro de un conjunto específico de horas.'
  },
  callConnectPhone: {
    label: 'Número de conexión de llamada',
    info: 'Introduzca el número de teléfono del negocio de la empresa. El negocio estará disponible para responder a estas llamadas telefónicas mientras el conjunto específico de horas.'
  },
  callConnectTranscript: {
    label: 'Trascripción de conexión de llamada',
    info: 'Si se establece, el botón "Call Connect" enviará automáticamente la transcripción visitante cuando la conexión inicial tiene éxito.'
  },
  callConnectHours: {
    label: 'Llame horas de conexión',
    info: 'El horario de conexión de llamadas para la empresa.'
  },
  insuranceCoverageResponse: {
    label: 'Respuesta de la cobertura del seguro',
    info: 'Si procede la información, específica que se debe dar sobre la cobertura del seguro.'
  },
  specialInstructions: {
    label: 'Instrucciones especiales',
    info: 'Coloque las instrucciones especiales que ayuden al equipo de ondas a configurar la cuenta.'
  },
  officeHoursTimezone: {
    label: 'Zona horaria',
    info: 'La zona horaria del negocio.'
  },
  createdOn: {
    label: 'Creado en',
    info: 'Cuando la compañía fue creada dentro del sistema.'
  },
  modifiedOn: {
    label: 'Modificado el',
    info: 'La última fecha se modificó la empresa.'
  },
  businessHours: {
    label: 'Horas de trabajo',
    info: 'Las horas de operaciones para el negocio.'
  },
  officeHours: {
    label: 'Horas de oficina',
    info: 'El horario de operaciones de la oficina.'
  },
  quickConnectHours: {
    label: 'Horario de conexión de llamadas',
    info: 'El horario de operaciones de Call Connect.'
  },
  billingEmailAddress: {
    label: 'Correo electrónico de contacto de facturación',
    info: 'Los destinatarios de correo electrónico que recibirán la información de facturación.'
  },
  address: { label: 'Dirección' },
  fax: { label: 'Fax' },
  inboundOutBound: { label: 'Programación Entrante o Saliente' },
  callTransfers: { label: 'Transferencias de Llamadas' },
  currentMonthlyCallVol: { label: 'Volumen de Llamadas Mensuales Actual' },
  callCenterHours: { label: 'Horario de Cobertura del Centro de Llamadas' },
  returnMissedCalls: { label: 'Devolver Llamadas Abandonadas/Perdidas' },
  minuteUsage: { label: 'Uso de Minutos Previsto' },
  expectedLiveDate: { label: 'Fecha de Puesta en Marcha Prevista' },
  name: { label: 'Nombre' },
  email: {
    label: 'Correo Electrónico',
    info: 'Solo soportado por Outlook y Gmail.'
  },
  phoneNumber: { label: 'Número de Teléfono' },
  personName: { label: 'Nombre del Contacto' },
  personEmail: { label: 'Correo Electrónico de la Persona de Contacto' },
  callTransferPhone: {
    label: 'Número de Teléfono para Transferencias de Llamadas'
  },
  contactDirectly: { label: '¿Podemos Contactar al Cliente Directamente?' },
  addPhoneNumber: { label: 'Agregar Número de Teléfono' },
  yes: { label: 'Sí' },
  no: { label: 'No' },
  appointmentScheduling: { label: 'Programación de Citas' },
  infoMsg1: {
    label:
      'Ofrecemos dos opciones para la programación de citas: Blanco y Autogestión. Por favor, elija la que mejor se ajuste a sus requisitos.'
  },
  infoMsg2: {
    label:
      'Para asignar un representante, primero debe Agregar un Representante al sistema.'
  },
  infoMsg3: { label: 'Ahora está listo para Agregar Tipo de Cita.' },
  whiteGloveScheduling: { label: 'Programación de Lujo' },
  whiteGloveDesc: {
    label:
      'Con la programación de lujo, tiene la flexibilidad de usar su propio enlace de calendario, como Calendly, lo que permite a nuestros agentes reservar citas fácilmente.'
  },
  selfServeScheduling: { label: 'Programación de Autogestión' },
  selfServeDesc: {
    label:
      'La programación de autogestión le permite utilizar nuestro calendario para generar enlaces de programación, que luego pueden ser utilizados para reservar citas en su nombre.'
  },
  representativeDetails: { label: 'Detalles del Representante' },
  addRepresentative: { label: 'Agregar Representante' },
  appointmentTypeDetails: { label: 'Detalles del Tipo de Cita' },
  addAppointmentType: { label: 'Agregar Tipo de Cita' },
  infoMsg4: {
    label:
      'Se enviará una invitación por correo electrónico a la dirección proporcionada. Por favor, haga un seguimiento con la persona después de enviar la invitación.'
  },
  provider: { label: 'Proveedor' },
  shiftHours: { label: 'Horas de turno' },
  addAnotherShift: { label: 'Agregar otro turno' },
  mediumOfAppointment: { label: 'Medio preferido de cita' },
  inPerson: { label: 'En persona' },
  phone: { label: 'Teléfono' },
  video: { label: 'Video' },
  groupMembers: { label: 'Miembros del grupo' },
  videoLink: { label: 'Enlace de video' },
  city: { label: 'Ciudad' },
  state: { label: 'Estado' },
  zip: { label: 'Código Postal' },
  country: { label: 'País' },
  aliasIsProfession: { label: 'Alias es Profesión' },
  article: {
    label: 'Artículo',
    info: 'Permitir al usuario añadir artículos en la descripción del correo electrónico cuando sea necesario.'
  },
  userAlias: { label: 'Alias del Usuario' },
  serviceProvider: { label: 'Proveedor de Servicios' },
  serviceTypes: { label: 'Gestionar tipos de servicios' },
  addPage: { label: 'Añadir página' },
  addThirdPartyPage: { label: 'Añadir página de terceros' },
  companyRepresentatives: { label: 'Gestionar representantes de la empresa' },
  uniqueLink: { label: 'Enlace Único' },
  salesRepresentatives: { label: 'Representantes de Ventas' }
};
export default sp_provisioningDetails;
