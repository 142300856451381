export const en_provisioningDetails = {
  heading1: 'Onboarding details',
  heading2: 'Pricing',
  heading3: 'Business operations',
  heading4: 'Attachments',
  heading5: 'Basic Details',
  heading6: 'Packages',
  features: {
    info: 'This is the {{description}} fee at {{amount}} charged {{paymentType}}',
    noFeatureSelectedWarningHeading: 'No Feature Selected',
    noFeatureSelectedWarningMessage:
      'You have not selected any feature. Do you wish to proceed?',
    yes: 'Yes',
    no: 'No'
  },
  companyName: {
    label: 'Company name',
    info: 'The name of the company.'
  },
  fileName: {
    label: 'File Name',
    info: ''
  },
  downloadFile: {
    label: 'Download',
    info: ''
  },
  companyKeyRequest: {
    label: 'Company key request',
    info: 'Use this field for the suggested Company Key.'
  },
  status: {
    label: 'Status',
    info: 'What step you are within the onboarding request.'
  },
  partnerRepresentativeName: {
    label: 'Partner representative name',
    info: 'Insert the name(s) who will receive email communication and main point of contact for the account.'
  },
  representativeEmail: {
    label: 'Representative email',
    info: 'Insert the email(s) who will receive email communication from the onboarding and main point of contact for the account.'
  },
  leadEmails: {
    label: 'Lead emails',
    info: 'The email receiepents who will receive the HTML lead.'
  },
  website: {
    label: 'Website',
    info: 'The website of the business.'
  },
  googleAnalyticsCode: {
    label: 'Google analytics code',
    info: 'Account code for Google Analytics.'
  },
  crm: {
    label: 'CRM',
    info: 'Select the CRM the client wants to integrate.'
  },
  crmEmail: {
    label: 'CRM Email',
    info: 'Select the CRM the client wants to integrate.'
  },
  setupInstructions: {
    label: 'Setup instructions',
    info: 'Use this field for any additional information that may be relevant.'
  },
  attachements: {
    label: 'Attachments',
    info: 'Provide necessary attachments (logo, agent, document, etc.)'
  },
  companyKey: {
    label: 'Company key',
    info: 'Insert a company key for the business (body of website url).'
  },
  businessCategory: {
    label: 'Business category',
    info: 'Select which industry best categorizes the business.'
  },
  subCategories: {
    label: 'Sub categories',
    info: 'Select a sub category for the business if an option is available.'
  },
  queries: {
    label: 'Queries',
    info: 'Queries'
  },
  additionalNotes: {
    label: 'Additional Notes',
    info: 'Additional Notes'
  },
  pricing: {
    label: 'Pricing',
    info: 'Provide pricing information for the billing department.'
  },
  setupFee: {
    label: 'Setup fee?',
    info: 'Enable the Setup fee if applicable.'
  },
  smsFee: {
    label: 'SMS fee?',
    info: 'Enable if the SMS feature is required.'
  },
  exitPopupFee: {
    label: 'Exit popup fee?',
    info: 'Enable if the Exit Pop-up feature is required.'
  },
  facebookFee: {
    label: 'Facebook fee?',
    info: 'Enable if the Facebook feature is required.'
  },
  spanishFee: {
    label: 'Spanish fee?',
    info: 'Enable if the business requires Spanish agents.'
  },
  autoDealerCRMEmail: {
    label: 'Auto dealer CRM email',
    info: 'Insert email(s) for the auto dealer CRM email.'
  },
  sendDealerCRMEmail: {
    label: 'Send dealer CRM email',
    info: 'Enable if the Auto deal CRM email feature is required.'
  },
  freeConsultation: {
    label: 'Free consultation',
    info: 'Enable if a free consultation is available.'
  },
  consultationFee: {
    label: 'Consultation fee',
    info: 'Please provide the consultation fee or useful information that may help regarding pricing.'
  },
  openAccount: {
    label: 'Open account',
    info: 'Open account'
  },
  // ==============
  areaOfCoverage: {
    label: 'Area of coverage',
    info: 'The coverage area for the business.'
  },
  linkToPrivacyPolicy: {
    label: 'Link to privacy policy',
    info: "A link to the company's privacy policy."
  },
  scriptTitlePosition: {
    label: 'Script title position',
    info: 'Title of position (in the script) of the person who will contact the visitor after the chat. EXAMPLE: Representative, Consultant, Supervisor, etc.'
  },
  hipaa: {
    label: 'HIPPA',
    info: 'Check this box if the company requires HIPPA.'
  },
  languages: {
    label: 'Language support',
    info: "Specify the languages requested for this company's scripts and agent support."
  },
  callConnect: {
    label: 'Call connect',
    info: 'If set, the agents will connect the visitor and business with a phone call during the live chat within a specific set of hours.'
  },
  callConnectPhone: {
    label: 'Call connect number',
    info: 'Insert the business phone number of the business. The business will be available to answer these phone calls during a specific set of hours.'
  },
  callConnectTranscript: {
    label: 'Call connect transcript',
    info: 'If set, the "Call Connect" button will automatically send the visitor transcript when the initial connect succeeds.'
  },
  callConnectHours: {
    label: 'Call connect hours',
    info: 'The call connect hours for the business.'
  },
  insuranceCoverageResponse: {
    label: 'Insurance coverage response',
    info: 'If applicable, specific information that should be given about insurance coverage.'
  },
  specialInstructions: {
    label: 'Special instructions',
    info: 'Place any special instructions that will help the onboarding team set up the account.'
  },
  officeHoursTimezone: {
    label: 'Time Zone',
    info: 'The Time Zone of the business.'
  },
  createdOn: {
    label: 'Created on',
    info: 'When the company was created within the system.'
  },
  modifiedOn: {
    label: 'Modified on',
    info: 'The last date the company was modified.'
  },
  businessHours: {
    label: 'Business hours',
    info: 'The hours of operations for the business.'
  },
  officeHours: {
    label: 'Office hours',
    info: 'The hours of operations for office.'
  },
  quickConnectHours: {
    label: 'Call Connect hours',
    info: 'The hours of operations for Call Connect.'
  },
  billingEmailAddress: {
    label: 'Billing Contact email',
    info: 'The email receiepents who will receive the billing information.'
  },
  address: {
    label: 'Address'
  },
  fax: { label: 'Fax' },
  inboundOutBound: { label: 'Inbound Or Outbound Programming' },
  leadDeliveryEmail: { label: 'Email Address for Lead Delivery' },
  servicesType: { label: 'What Type Of Services Are You Using?' },
  callTransfers: { label: 'Call Transfers' },
  currentMonthlyCallVol: { label: 'Current Monthly Call Volume' },
  callCenterForwardingNumber: { label: 'Call Center Forwarding Number' },
  monthlyMin: { label: 'Monthly Min' },
  pricePerMinute: { label: 'Price Per Minute' },
  emailAddressReporting: { label: 'Email Address for Reporting' },
  billingEmail: { label: 'Email Address for billing' },
  contactSigningEnabled: { label: 'Contact signing feature' },
  contactSigningFee: { label: 'Contract Signing Fee' },
  billingStartDate: { label: 'Billing Start Date' },
  callCenterObjective: { label: 'Objective for Call Center Service' },
  currentCallCenterMoving: {
    label: 'Are You Moving From A Current Call Center Provider'
  },
  currentCallCenterProvider: { label: 'Current call center provider' },
  currentCallCenterUsage: { label: 'Minutes usage with current call Center' },
  currentCallCenterCost: { label: 'Cost with current call center' },
  currentCallCenterLeaveReason: {
    label: 'Reason for leaving current call center provider'
  },
  callCenterHours: { label: 'Hours For Call Center Coverage' },
  returnMissedCalls: { label: 'Return Abandoned/Missed Calls' },
  minuteUsage: { label: 'Anticipated Minute Usage' },
  expectedLiveDate: { label: 'Anticipated Live Date' },
  name: { label: 'Name' },
  email: { label: 'Email', info: 'Only Outlook and Gmail support' },
  phoneNumber: { label: 'Phone Number' },
  personName: { label: 'Contact Person Name' },
  personEmail: { label: 'Contact Person Email' },
  callTransferPhone: {
    label: 'Call Transfers',
    info: 'Add and manage representatives by enabling call transfers'
  },
  contactDirectly: { label: 'Can We Contact The Customer Directly?' },
  addPhoneNumber: { label: 'Add Phone Number' },
  yes: { label: 'Yes' },
  no: { label: 'No' },
  cancel: { label: 'Cancel' },
  save: { label: 'Save' },
  appointmentScheduling: { label: 'Appointment Scheduling' },
  infoMsg1: {
    label:
      'We provide two options for appointment scheduling <b>White Glove</b> and <b>Self Serve</b>. Please choose the one that aligns best with your requirements.'
  },
  infoMsg2: {
    label:
      'To allocate a representative, you must first <b>Add Representative</b> to the system.'
  },
  officialBusinessName: { label: 'Official Business Name' },
  officialBusinessAddress: { label: 'Official Business Address' },
  title: { label: 'Title' },
  infoMsg3: { label: "You're all set to <b>Add Appointment Type</b> now." },
  whiteGloveScheduling: { label: 'White Glove Scheduling' },
  whiteGloveDesc: {
    label:
      'With White Glove scheduling, you have the flexibility to use your own calendar link, such as Calendly, allowing our agents to easily book appointments.'
  },
  selfServeScheduling: { label: 'Self Serve Scheduling' },
  selfServeDesc: {
    label:
      'Self-Serve scheduling enables you to utilize our calendar to generate scheduling links, which can then be used to book appointments on your behalf.'
  },
  representativeDetails: { label: 'Representative Details' },
  addRepresentative: { label: 'Add Representative' },
  appointmentTypeDetails: { label: 'Appointment Type Details' },
  addAppointmentType: { label: 'Add Appointment Type' },
  infoMsg4: {
    label:
      'An email invite will be sent to the provided email address. Please follow up with the person after sending the invite.'
  },
  provider: { label: 'Provider' },
  shiftHours: { label: 'Shift Hours' },
  addAnotherShift: { label: 'Add Another Shift' },
  appointmentName: { label: 'Appointment Name' },
  duration: { label: 'Duration' },
  mediumOfAppointment: { label: 'Preferred Medium of Appointment' },
  inPerson: { label: 'In-Person' },
  phone: { label: 'Phone' },
  video: { label: 'Video' },
  groupMembers: { label: 'Group Members' },
  videoLink: { label: 'Video Link' },
  appointmentLink: { label: 'Appointment Link' },
  city: { label: 'City' },
  state: { label: 'State' },
  zip: { label: 'Zip Code' },
  country: { label: 'Country' },
  aliasIsProfession: { label: 'Alias is Profession' },
  article: {
    label: 'Article',
    info: 'Allow user to add articles in email description when needed.'
  },
  userAlias: { label: 'User Alias' },
  serviceProvider: { label: 'Service Provider' },
  serviceTypes: { label: 'Manage Service Types' },
  addPage: { label: 'Add Page' },
  addThirdPartyPage: { label: 'Add Third Party Page' },
  companyRepresentatives: { label: 'Manage Company Representatives' },
  uniqueLink: { label: 'Unique Link' },
  salesRepresentatives: { label: 'Sales Representatives' }
};
export default en_provisioningDetails;
export { default as fr_provisioningDetails } from '../fr-FR-ProvisioningDictionary/fr-provisioningDetails';
export { default as sp_provisioningDetails } from '../sp-SP-ProvisioningDictionary/sp-provisioningDetails';
